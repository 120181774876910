import Api from "./Api";
import Newsletter from "./Newsletter";

class ExitFeedback {

  async sendExitFeedback(feedbackOption, otherComment, converted, feedbackId = null) {
    const personInfo = Newsletter.getLocalEntry();
    const name = personInfo?.firstname || '';
    const email = personInfo?.email || '';

    const data = {
      name,
      email,
      feedback_option: feedbackOption,
      other_comment: otherComment,
      converted
    };

    try {
      const response = feedbackId
        ? await Api.put(`exit-feedback/${feedbackId}`, data)
        : await Api.post('exit-feedback', data);

      if (response.status === 200) {
        const { data } = response.data;
        return data;
      }
    } catch (error) {
      if (error.response?.status === 404) {
        return null
      } else {
        console.log("An error occurred:", error.message);
      }
    }
    return null;
  }

}

export default new ExitFeedback();