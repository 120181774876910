import React, {useEffect, useState} from "react"
import Slider from "react-slick";
import Reviews from "../../../../lib/Reviews";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import { useTranslation } from "react-i18next";

const ExpertReviewCarousel = () => {
  const [expertReviews, setExpertReviews] = useState([]);
  const [t] = useTranslation('home');

  useEffect(() => {
    Reviews.getExpertReviews().then((response) => {
      setExpertReviews(
        response.data.response.data.filter((review) => review.lang)
      );
    });
  }, [t])

  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <i className="fas fa-chevron-right slick-slider-right-btn" onClick={onClick} style={{ ...style, display: "block" }}></i>
    );
  }

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <i className="fas fa-chevron-left slick-slider-left-btn" onClick={onClick} style={{ ...style, display: "block" }}></i>
    );
  }
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    autoplay:true,
    autoplaySpeed:3000,
    slidesToShow: 1,
    fade: true,
    cssEase: 'linear',
    speed:500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div className="modern-review-slider">
      <Slider {...settings}>
        {expertReviews.map(review => (
          // <div key={review.id} className="modern-review-slider-item-wrapper">
            <div key={review.id} className="text-center modern-review-slider-item">
              <div className="modern-review-slider-item-image">
                <img src={'https://bestbikeadvice.com/' + review.image} alt={review.author + ' ' + review.lang.title} />
              </div>
              <div className="modern-review-slider-item-author">
                <div className="author-name">{review.author}</div>
                <div className="author-title">{review.lang.title}</div>
                <img src={'https://bestbikeadvice.com/' + review.logo} alt={review.author + ' ' + review.lang.title} />
              </div>
              <div className="modern-review-slider-item-content" dangerouslySetInnerHTML={{__html: review.lang.content}}>
              </div>
            </div>
          // </div>
        ))}

        {/* {expertReviews.map(review => (
          <div key={review.id + "-2"} className="modern-review-slider-item text-center">
            <div className="modern-review-slider-item-image">
              <img src={'https://bestbikeadvice.com/' + review.image} alt={review.author + ' ' + review.lang.title} />
            </div>
            <div className="modern-review-slider-item-author">
              <div className="author-name">{review.author}</div>
              <div className="author-title">{review.lang.title}</div>
              <img src={'https://bestbikeadvice.com/' + review.logo} alt={review.author + ' ' + review.lang.title} />
            </div>
            <div className="modern-review-slider-item-content" dangerouslySetInnerHTML={{__html: review.lang.content}}>
            </div>
          </div>
        ))} */}
      </Slider>
    </div>
  )
}

export default ExpertReviewCarousel;
