
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import Reviews from "../../../../lib/Reviews";
import DynamicTitleDescription from "../DynamicTitleDescription/DynamicTitleDescription";
import './style.css'

const ExpertReview = ({ t }) => {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  const [expertReviews, setExpertReviews] = useState([]);

  useEffect(() => {
    Reviews.getExpertReviews().then((response) => {
      setExpertReviews(
        response.data.response.data.filter((review) => review.lang)
      );
    });
  }, [t])

  return (
    <div className="slider-container py-2 py-md-5" style={{ backgroundColor: '#585858' }}>
      <div className="container p-md-0 p-3">

        <div className="d-md-flex justify-content-between">
          <div>
            <DynamicTitleDescription title={t("Expert Beoordelingen")} styledWord="Beoordelingen Reviews" color="#F4DE58" classNames={'ml-0 text-white'} />
          </div>
          {/* buttons */}
          <div className="mb-3">
            <button className="button arrowButton mr-3" onClick={previous}>
              <IoIosArrowBack size={25} />
            </button>
            <button className="button arrowButton" onClick={next}>
              <IoIosArrowForward size={25} />
            </button>

          </div>

        </div>

        <Slider
          ref={slider => {
            sliderRef = slider;
          }}
          {...settings}
        >

          {
            expertReviews?.map((review, index) => (
              <div key={index}>
                <div className="bg-white expert-card mx-md-2 mx-lg-3 mx-0">
                  <div className=" p-4">
                    <div className="d-flex mb-3">
                      <img className="expert-image" src={'https://bestbikeadvice.com/' + review.image} alt={review.author + ' ' + review.lang.title} />
                      <div className="ml-3">
                        <p className="author">{review.author}</p>
                        <p className="title-for-expert">{review.lang.title}</p>
                      </div>
                    </div>
                    <div className="modern-review-slider-item-content mb-5 font-18px" dangerouslySetInnerHTML={{ __html: review.lang.content }}>
                    </div>
                    <div className="border-top d-flex justify-content-end">
                      <img className="expert-logo" src={'https://bestbikeadvice.com/' + review.logo} alt={review.author + ' ' + review.lang.title} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </Slider>
      </div>

    </div>
  );
}
export default ExpertReview;