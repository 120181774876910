import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./checkoutStyle.css";
import BikefitPlansForCheckout from "./BikefitPlansForCheckout";
import Reviews from "../../lib/Reviews";
import ExpertCardCheckout from "../../pages/Info/infoReview/Reviews/ExpertCardCheckout/ExpertCardCheckout";
import TrustScoreBlack from "../../pages/Homes/BikefitHome/img/Best-bike-advice-beoordelingen.jpg";
import TrustScore from "../../pages/Homes/BikefitHome/img/trustpilot_white_transperant.png";
import ArrowImage from "../../pages/Homes/BikefitHome/img/arrow_image.png";
import Footer from "../Footer";
import Locale from "../../lib/Locale";
import ReactGA from 'react-ga4';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import ReviewMultiCarousel from "../../pages/Homes/components/ReviewMultiCarousel";
import ExpertReviewMultiCarousel from "../../pages/Homes/components/ExpertReviewMultiCarousel";
import CustomerCard from '../Reviews/Customer/CustomerCard';
import ContactForm from '../Contact';
import PreventNavigationDialog from "../PreventNavigationDialog";
import Redirections from "../../lib/Redirections";
import Subscription from "../../lib/Subscription";
import { chooseSubscription, setReturnUrl } from "../../redux/actions/checkout";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormatter from "../../lib/CurrencyFormatter";
import useFeatureFlag from "../../hooks/useFeatureFlag";
import { selectBikefitCurrentChoice } from "../../redux/selectors/bikefit";

/* This one is the actual checkout page */
const BikefitPurchaseOptions = ({ extendingAccountSubscriptionId = 0, extension = false }) => {
  const [t, i18n] = useTranslation("subscription");
  const dispatch = useDispatch();
  const simplifiedCheckout = useFeatureFlag("simplified_checkout")
  const isCurrentChoiceEmpty = useSelector(selectBikefitCurrentChoice(0));

  const [expertReviews, setExpertReviews] = useState([]);
  const [customerReviews, setCustomerReviews] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [bikefitOnce, setBikefitOnce] = useState({});
  const [returnUrl, setLocalReturnUrl] = useState(
    Locale.getUrlForCurrentLocale("/bikefit/result-page")
  );

  useEffect(() => {
    if (!isCurrentChoiceEmpty) {
      window.location.href = "/bikefit/bike-type"
    }
  }, [isCurrentChoiceEmpty])
  const loadReviews = () => {
    Reviews.getExpertReviews().then((response) => {
      if (response.data.response.data) {
        setExpertReviews(
          response.data.response.data.filter((review) => review.lang)
        );
      }
    });

    Reviews.getCustomerReviews().then((response) => {
      setCustomerReviews(
        response.data.response.data.filter((review) => review.lang)
      );
    });
  };

  useEffect(() => {
    loadReviews();
    processReturnUrlParameter();

    ReactGA.event('view_item_list', { item_list_id: 'bikefit', item_list_name: 'Bikefit' });
  }, [i18n.language]);
  useEffect(() => {
    setBikefitOnce(subscriptions?.find(subscription => subscription.code === 'bikefit_once'))
  }, [subscriptions])
  const processReturnUrlParameter = () => {
    const parameterValue = (window.location.search.match(new RegExp(`[?&]returnUrl=([^&]+)`)) || [null, null])[1];

    if (parameterValue) {
      setLocalReturnUrl(
        Locale.getUrlForCurrentLocale(decodeURIComponent(parameterValue))
      );
    }
  };
  useEffect(() => {
    const hash = window.location.hash
    const scrollToHash = () => {
      document.getElementById("tab-section").scrollIntoView({ behavior: "smooth" })
    }
    if (hash) {
      scrollToHash()
    }

  }, [expertReviews, customerReviews]);

  const loadSubscriptions = () => {
    Subscription.getSubscriptions('bikefit')
      .then(subscriptions => {
        setSubscriptions(subscriptions.filter(subscription => {
          return extension ? subscription.needs_account : true
        }))
      })
  }

  const handleBuyBikefit = () => {
    const subscription = subscriptions.find(subscription => subscription?.code === "account")
    if (subscription) {
      ReactGA.event('add_to_cart', {
        currency: subscription.price.currency,
        value: subscription.price.price / 100,
        items: [{
          item_id: subscription.code,
          item_name: `Product type: ${subscription.type}, code: ${subscription.code}`,
          item_category: subscription.type,
          price: subscription.price.price / 100,
          currency: subscription.price.currency
        }]
      });
      dispatch(chooseSubscription(subscription, extension, extendingAccountSubscriptionId))
      dispatch(setReturnUrl(returnUrl))
      Redirections.goTo('/subscription/payment/data')
    }
  }

  const clickSubscription = subscription => {
    ReactGA.event('add_to_cart', {
      currency: subscription.price.currency,
      value: subscription.price.price / 100,
      items: [{
        item_id: subscription.code,
        item_name: `Product type: ${subscription.type}, code: ${subscription.code}`,
        item_category: subscription.type,
        price: subscription.price.price / 100,
        currency: subscription.price.currency
      }]
    });
    dispatch(chooseSubscription(subscription, extension, extendingAccountSubscriptionId))
    dispatch(setReturnUrl(returnUrl))
    Redirections.goTo('/subscription/payment/data')

  }

  useEffect(() => {
    loadSubscriptions();
  }, [i18n])
  return (
    <div className="main bg-dark checkout pt-md-5rem pt-2">
      <PreventNavigationDialog shouldBlockDialogShown={isCurrentChoiceEmpty} />
      {
        simplifiedCheckout ?
          <div className="simplified-checkout-wrapper">
            <div className="container checkout-container my-5">
              <div className="easy-checkout">
                <h1 className="header">{t("get_your_personal_bike_fit_report")}</h1>
                <h3 className="text-white">{t("for_just") + " " + CurrencyFormatter.format(bikefitOnce?.price?.price ? bikefitOnce.price.price / 100 : 0, bikefitOnce?.price?.currency)}</h3>
                <ul>
                  {
                    bikefitOnce?.lang?.points_html?.split('\n')
                      .map((feature, index) => (
                        <li key={index} >
                          {feature.replace(/<\/?li>/g, '')}
                        </li>
                      ))
                  }
                </ul>
                <p className="checkout-customer-msg">{t("already_more_than_10000_satisfied_customers")}</p>
                <hr className="border-secondary" />
              </div>
              <div className="easy-checkout-bottom d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="d-inline-flex flex-column mt-1">
                  <button className="btn btn-secondary mb-2" onClick={() => clickSubscription(bikefitOnce)}>{t("get_bike_fit_report")}</button>
                  <i>{t("not_satisfied_money_back_guarantee")}</i>
                </div>
                <a
                  href="https://nl.trustpilot.com/review/bestbikeadvice.com"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img className="trustbox-subscribe" alt="..." src={TrustScore} />
                </a>
              </div>
            </div>
          </div>
          :
          <>
            <div className="container my-md-4">
              <div className="row">
                <div className="col-lg-5 pt-lg-5 pr-lg-5">
                  <h1 className="text-white mt-lg-5"><strong>{t('CHECKOUT_Get Your Personal')}</strong></h1>
                  <h1 className="text-warning"><strong>{t('CHECKOUT_Bike Fit Report')}</strong></h1>
                  <p className="text-light-custom h5">{t('CHECKOUT_Already more than 10,000 satisfied customers')}</p>
                  <hr className="border-secondary" />
                  {/* <p className="mt-4"><a href="" className="text-danger">{t('CHECKOUT_Bike Fit Example')} <i className="fa fa-external-link-alt ml-2" aria-hidden="true"></i></a></p> */}
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <a
                        href="https://nl.trustpilot.com/review/bestbikeadvice.com"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <img className="trustbox-subscribe mt-2" alt="..." src={TrustScore} />
                      </a>
                    </div>
                    <div className="col-lg-6 text-center d-none d-lg-block">
                      <img width={'50%'} alt="..." src={ArrowImage} style={{ WebkitTransform: "scaleY(-1) scaleX(-1)", transform: "scaleY(-1) scaleX(-1)", position: "relative", top: "31px" }} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <button
                        onClick={() => document.getElementById("tab-section").scrollIntoView({ behavior: "smooth" })}
                        className="btn btn-secondary mx-lg-auto mt-lg-3">{t('Watch example')}</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <BikefitPlansForCheckout returnUrl={returnUrl} subscriptions={subscriptions} />
                </div>
              </div>
            </div>
            <div className="mt-5 mb-5 text-center">
              <i className="fas fa-chevron-down scroll-down"></i>
            </div>
            <div className="container bg-white tabs-section" id="tab-section">
              <Tabs className="nav-justified">
                <Tab label={t('CHECKOUT_Bike Fit Example')}>
                  <div className="row mb-2">
                    <div className="col-12 col-md-8">
                      <h1><strong>{t('CHECKOUT_Bike Fit Example')}</strong></h1>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      <div>
                        <button className="btn btn-secondary" onClick={handleBuyBikefit}>{t("Buy_Your_Bike_Fit_Report")} </button>
                      </div>
                    </div>
                  </div>
                  <p className="text-muted">{t('CHECKOUT_This is not your bike fit report')}<br />{t('CHECKOUT_It is an anonymous example so you can get an impression of how your bike fit report will look like')}</p>
                  <div className="row text-center relative">
                    <div className="col-md-12">
                      <img className="img-responsive" src={`/img/bikefit/rapport-${Locale.getLanguage()}-1.png`} alt="bikefit example" />
                    </div>
                    <div className="col-md-12">
                      <img className="img-responsive" src={`/img/bikefit/rapport-${Locale.getLanguage()}-2.png`} alt="bikefit example" />
                    </div>
                    <div className="col-md-12">
                      <img className="img-responsive" src={`/img/bikefit/rapport-${Locale.getLanguage()}-3.png`} alt="bikefit example" />
                    </div>
                    <div className="col-md-12">
                      <img className="img-responsive" src={`/img/bikefit/rapport-${Locale.getLanguage()}-4.png`} alt="bikefit example" />
                    </div>

                    {/* watermark */}
                    <div className="watermark">
                      {t("example_report_not_based_on_own_data")}
                    </div>
                  </div>

                </Tab>
                <Tab label={t('CHECKOUT_Customer Reviews')}>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <h1><strong>{t('CHECKOUT_Customer Reviews')}</strong></h1>
                    </div>
                    <div className="col-md-6 text-md-right">
                      <a
                        href="https://nl.trustpilot.com/review/bestbikeadvice.com"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <img className="trustbox-subscribe" alt="..." src={TrustScoreBlack} />
                      </a>
                    </div>
                  </div>
                  <ReviewMultiCarousel
                    interval={5000}
                    reviews={customerReviews}
                    component={(review) => <CustomerCard cardClass="w-auto mx-2" review={review} />}
                  />
                </Tab>
                <Tab label={t('CHECKOUT_Expert Reviews')}>
                  <div className="row mb-2">
                    <div className="col-md-12">
                      <h1><strong>{t('CHECKOUT_Expert Reviews')}</strong></h1>
                    </div>
                  </div>
                  <ExpertReviewMultiCarousel
                    interval={5000}
                    reviews={expertReviews}
                    component={(props) => <ExpertCardCheckout {...props} />}
                  />
                </Tab>
                <Tab label={t('CHECKOUT_Best Value For Money')}>
                  <h1 className="mb-5"><strong>{t('CHECKOUT_Reliable Affordable Online')}<br />{t('CHECKOUT_Bike Fit with Gaurantee')}</strong></h1>
                  <div className="row">
                    <div className="col-md-6">
                      <h4><strong>{t('CHECKOUT_Best Bike Advice vs free bike fit tools')}</strong></h4>
                      <p className="text-muted">{t('CHECKOUT_You cannot compare our online Bike Fit with any free calculation tools')}</p>
                      <div className="text-muted">
                        <ul>
                          <li>{t('CHECKOUT_Your cycling goals')}</li>
                          <li>{t('CHECKOUT_Your cycling experience and level')}</li>
                          <li>{t('CHECKOUT_Your body flexibility')}</li>
                          <li>{t('CHECKOUT_Many body measurements')}</li>
                          <li>{t('CHECKOUT_Your cycling environment')}</li>
                          <li>{t('CHECKOUT_Your bike touch-points (crank, saddle and bar)')}</li>
                          <li>{t('CHECKOUT_Your fork and shock sag (for MTB)')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4><strong>{t('CHECKOUT_Best Bike Advice vs physical bike fitters')}</strong></h4>
                      <p className="text-muted">{t('CHECKOUT_The most reliable bike fit is a dynamic bike fit')}</p>
                      <p className="text-muted">{t('CHECKOUT_But most people do not have medical issues')}</p>
                    </div>
                  </div>
                </Tab>
              </Tabs>


            </div>
            <div className="container bg-white">
              <div className="mt-5 tab-content">
                <ContactForm />
              </div>

            </div>
          </>
      }



      {!simplifiedCheckout && <Footer />}
    </div>
  );
};

export default BikefitPurchaseOptions;
