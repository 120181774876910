import {CHECKOUT_FINISHED, CHECKOUT_VISITED, CHOOSE_SUBSCRIPTION, SET_RETURN_URL} from './checkoutActionTypes';
import Newsletter from "../../lib/Newsletter";
import Api from "../../lib/Api";
import {selectCheckoutId, selectCheckoutReturnUrl} from "../selectors/checkout";
import Locale from "../../lib/Locale";

const checkoutVisited = () => (dispatch, getState) => {
    const checkoutSessionId = selectCheckoutId(getState());
    const returnUrl = selectCheckoutReturnUrl(getState());
    const url = checkoutSessionId ? `/subscription/checkout-session/${checkoutSessionId}` : "/subscription/checkout-session";

    const newsletterEntry = Newsletter.getLocalEntry();

    const data = {
        name: newsletterEntry.firstname || '',
        email: newsletterEntry.email || '',
        returnUrl: returnUrl || Locale.getUrlForCurrentLocale("/bikefit/result-page")
    };

    Api.post(url, data).then(response => {
        const data = response.data?.response?.data;
        dispatch({
            type: CHECKOUT_VISITED,
            data
        });
    });
};

const checkoutFinished = () => ({
    type: CHECKOUT_FINISHED
});

const chooseSubscription = (subscription, extension = false, extendingAccountSubscriptionId = 0) => ({
    type: CHOOSE_SUBSCRIPTION,
    subscription,
    extension,
    extendingAccountSubscriptionId
});

const setReturnUrl = returnUrl => ({
    type: SET_RETURN_URL,
    returnUrl
});

export {chooseSubscription, setReturnUrl, checkoutVisited, checkoutFinished};
