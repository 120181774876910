import Redirections from "./Redirections";
import Api from "./Api";
import Auth from "./Auth";
import i18n from "../i18n";
import Locale from "./Locale";

class Subscription {
    paywall(type, trajectory = null, returnUrl = '') {
        let url = '/subscription/payment/' + type;
        if (trajectory !== null) {
            url += '/' + trajectory
        }
        if (returnUrl !== '') {
            url += '?returnUrl=' + encodeURIComponent(returnUrl)
        }

        Redirections.replaceTo(url);
    }

    getSubscriptions(type) {
        return new Promise((resolve, reject) => {
            Api.get(`/subscriptions/${type}`)
                .then(response => {
                    if (response.data.response.status === 1000) {
                        resolve(response.data.response.data || [])
                    } else {
                        reject(response.data)
                    }
                })
                .catch(e => reject(e))
        });
    }

    checkSubscription(type) {
        return new Promise((resolve, reject) => {
            if (type === 'bikefinder') {
                resolve(true)
            }

            Api.get(`subscription/${type}/check`)
                .then(response => {
                    switch (response.data.response.status) {
                        case 1000:
                            resolve(true)
                            break;
                        default:
                            resolve(false)
                    }

                })
                .catch(error => {
                    reject(error)
                });
        });
    }

    createOrderAndGoToPayment(
        type,
        subscriptionCode,
        returnUrl,
        extension = false,
        extensionAccountSubscriptionId = 0,
        firstName = null,
        email = null,
        discountToken = null,
        lastName = null,
        password = null,
        policy = null,
        optinData = null,
        optinMail = null,
        useReplace = false
    ) {
        this.getSubscriptions(type)
            .then(subscriptions => {
                const subscription = subscriptions.filter(subscription => subscription.code === subscriptionCode)[0] || null;

                if (subscription === null) {
                    alert(i18n.t('Subscription_NotFound', {code: subscriptionCode}))
                }

                const redirectUrl = extension
                  ? Locale.getUrlForCurrentLocale('/subscription/bikefit-extension/status')
                  : Locale.getUrlForCurrentLocale('/subscription/status');

                let vars = {
                    type,
                    subscriptionCode,
                    extension,
                    extensionAccountSubscriptionId,
                    redirectUrl,
                    returnUrl,
                    firstName,
                    email,
                    lastName,
                    password,
                    policy,
                    optinData,
                    optinMail,
                    discountToken
                }
                Api.post('order', vars)
                    .then(response => {
                        switch (response.data.response.status) {
                            case 1000:
                                const currentLang = `lang=${Locale.getLanguage().toUpperCase()}`;
                                const paymentUrl = response.data.response.data.checkoutUrl?.replace('lang=NL', currentLang);
                                Redirections.goTo(paymentUrl);
                                break;
                            case 1008:
                                // Redirect instead of alert to give the client a chance to login
                                Redirections.replaceTo(Locale.getUrlForCurrentLocale('/subscription/login'));
                                break;
                            default:
                                alert(i18n.t('GeneralError'))
                        }
                    })
                    .catch(() => {
                        alert(i18n.t('GeneralError'))
                    });
            })
            .catch(error => {
                alert(i18n.t('GeneralError'))
            })
    }

    processOrderStatus(orderId) {
        return new Promise((resolve, reject) => {
            Api.get('order/' + orderId + '/process-status')
                .then(response => {
                    switch (response.data.response.status) {
                        case 1000:
                            const token = response.data.response.data.token || false;
                            const status = response.data.response.data.status;
                            const returnUrl = response.data.response.data.returnUrl;
                            const code = response.data.response.data.code;
                            const type = response.data.response.data.type;
                            const price = response.data.response.data.price;
                            const valuta = response.data.response.data.valuta;
                            const needsAccount = response.data.response.data.needsAccount;
                            const accountHasPassword = response.data.response.data.accountHasPassword;

                            if (status === 'PAID') {
                                if (token !== false) {
                                    if (needsAccount) {
                                        Auth.setAuth(token, 'login');
                                    } else {
                                        Auth.setAuth(token, 'token');
                                    }
                                    resolve({success: true, returnUrl, needsAccount, accountHasPassword, type, code, price, valuta, token})
                                } else if( ! Auth.isLoggedIn()) {
                                    reject({response, code: 'TokenAlreadyGiven'})
                                } else {
                                    resolve({success: true, returnUrl, needsAccount, accountHasPassword, type, code, price, valuta, token})
                                }
                            } else {
                                resolve({success: false, returnUrl, needsAccount, accountHasPassword, type, code, price, valuta, token})
                            }
                            break;
                        default:
                            reject({response, code: 'Unknown'})
                    }
                })
                .catch(e => {
                    reject(e)
                });
        });
    }

    setupTokenAccount(firstName, lastName, email, password, optinMail, optinData, policy) {
        return new Promise((resolve, reject) => {
            Api.post('/subscription/token-account', {
                firstname: firstName,
                lastname: lastName,
                email,
                password,
                optin_mail: optinMail,
                optin_data: optinData,
                policy
            })
                .then((response) => {
                    if (response.data.response.status === 1000) {
                        resolve()
                    } else {
                        reject(response.data.response.status)
                    }
                })
                .catch(() => reject(999))
            }
        );
    }
}
// eslint-disable-next-line
export default new Subscription();
