import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DynamicTitleDescription from '../DynamicTitleDescription/DynamicTitleDescription';
import BikeFittingButton from '../button/BikeFittingButton';
import LightGreyContainer from '../containers/LightGreyContainer/LightGreyContainer';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import CurrencyFormatter from '../../../../lib/CurrencyFormatter';
import { useTranslation } from 'react-i18next';

const PriceCard = ({ t, _subscriptions }) => {
  const [subscriptions, setSubscriptions] = useState([])
  const isFreeReport = useFeatureFlag('free_report');
  const [t2] = useTranslation("subscription")
  useEffect(() => {
    setSubscriptions(_subscriptions?.filter(subscription => isFreeReport ? subscription.needs_account : true))
  }, [isFreeReport, _subscriptions])

  return (
    <LightGreyContainer style={{ backgroundColor: '#F5755D' }}>
      <DynamicTitleDescription title={t("Prijzen")} classNames={'text-center text-white margin-bottom-70px'} />
      <Row xs={1} lg={3} className="mb-5 mx-auto d-flex justify-content-center align-content-center">
        {
          isFreeReport &&
          <Col className='d-flex justify-content-center align-content-center p-0'>
            <Card className={`price-card`}>
              <Card.Header className={`d-flex justify-content-center align-content-center py-4`}>
                <h4 className='h4 m-0 text-dark-gray'>{t2('BIKE_FIT_INSIGHTS')}</h4>
              </Card.Header>
              <Card.Body className='text-left py-md-2 py-lg-3 py-2 pb-lg-5 px-0' style={{ background: '#f4f4f4' }}>
                <h3 className='text-center my-3 text-xl text-dark-gray font-weight-900'>{t2('FREE')}</h3>
                <ul>
                  <li className='custom-list text-l'>{t2('BASIC_BIKE_FIT_INSIGHTS')}</li>
                  <li className='custom-list text-l'>{t2('BIKE_PERSONALIZATION_REQUIREMENTS')}</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        }
        {
          subscriptions?.map((subscription, index) =>
            <Col key={index} className='d-flex justify-content-center align-content-center p-0'>
              <Card className={`price-card ${subscription.lang.title === 'An account for cyclists' ? 'active-card' : 'd-active-card'}`}>
                <Card.Header className={`${subscription.lang.title === 'An account for cyclists' && 'active-card-header'} d-flex justify-content-center align-content-center py-4`}>
                  <h4 className='h4 m-0 text-dark-gray'>{subscription.lang.title}</h4>
                </Card.Header>
                <Card.Body className='text-left py-md-2 py-lg-3 py-2 pb-lg-5 px-0' style={{ background: '#f4f4f4' }}>
                  <h3 className='text-center my-3 text-xl text-dark-gray font-weight-900'>{CurrencyFormatter.format(subscription.price.price / 100, subscription.price.currency)}</h3>
                  <ul>
                    {
                      subscription?.lang.points_html?.split('\n')
                        .map((feature, index) => (
                          <li key={index} className='custom-list text-l'>
                            {feature.replace(/<\/?li>/g, '')}
                          </li>
                      ))
                    }
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          )}
      </Row>
      <div style={{ marginTop: '100px' }} className='text-center'>

        <BikeFittingButton style={{ textAlign: 'center' }} buttonColor="#F4DE58" />
      </div>
    </LightGreyContainer >
  );
};

export default PriceCard;
