import React, { useEffect, useState } from 'react';
import DataImageSection from './components/DataImageSection/DataImageSection';
import ExpertReview from './components/ExpertReview/ExpertReview';
import FeatureListSection from './components/FeatureListSection/FeatureListSection';
import FeatureSection from './components/FeatureSection/FeatureSection';
import Hero from './components/Hero/Hero';
import PriceCard from './components/PriceCard/PriceCard';
import Footer from './components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import DocumentMeta from 'react-document-meta';
import Subscription from '../../lib/Subscription';

const NewLandingPage = () => {
  const { t } = useTranslation("newLandingPage")
  const [subscriptions, setSubscriptions] = useState([])

  const dataForDataImageSection = [
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je klachten")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je vraagt")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je weet")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je zoekt")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Je zoekt een")
    },
  ]
  const dataForDataImageSection2 = [
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Geniet meer")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Snel, goedkoop")
    },
    {
      icon: "img/newLandingPage/icon/Cassette_Icon_Red.png",
      title: t("Niet tevreden geld")
    }
  ]
  const customCardData = [
    {
      icon: "img/newLandingPage/icon/Faster_Icon.png",
      title: t("Sneller"),
      description: t("Makkelijk thuis")
    },
    {
      icon: "img/newLandingPage/icon/Cheaper_Icon.png",
      title: t("Goedkoper"),
      description: t("Bespaar ongeveer")
    },
    {
      icon: "img/newLandingPage/icon/Verified_Icon.png",
      title: t("Betrouwbaar"),
      description: t("Niet tevreden")
    },
  ]
  const customCardData2 = [
    {
      title: t("Start de bikefit"),
      description: t("Start de bikefit en")
    },
    {
      title: t("Stel je"),
      description: t("Stel je fiets")
    },
    {
      title: t("Geniet van het fietsen"),
      description: t("Ga op weg")
    },
  ]


  const meta = {
    title: t("Bike_Fitting_meta_title"),
    description: t("Bike_Fitting_meta_description"),
  };

  useEffect(() => {
    Subscription.getSubscriptions('bikefit')
      .then(subscriptions => {
        setSubscriptions(subscriptions)
      })
  }, [t])

  return (
    <div className='bg-white'>

      <DocumentMeta {...meta} />

      <Hero subscriptions={subscriptions} />

      <DataImageSection
        data={dataForDataImageSection}
        imagePath="img/newLandingPage/hero/Back_pain_smaller.png"
        title={t("Herken je dat")}
      />

      <FeatureSection
        title={t("Online Bikefitting")}
        styledWord="Bikefitting: Online Bike Fitting:"
        description={t("Ontdek de voordelen")}
        customCardData={customCardData}
      />

      <FeatureListSection
        title={t("Wij begrijpen")}
        styledWord="pijn of klachten pain or discomfort"
        description={t("Bij Best Bike")}
      />

      <FeatureSection
        title={t("In slechts")}
        styledWord="genieten Enjoy"
        customCardData={customCardData2}
        badgeStyle={{ backgroundColor: '#F4DE58' }}
      />

      <PriceCard
        _subscriptions={subscriptions}
        t={t}
      />

      <ExpertReview t={t} />

      <DataImageSection
        imagePath="img/newLandingPage/hero/3 enjoy cycling.jpg"
        title={t("Bij Best Bike Advice")}
        styledWord="maximaal genieten enjoy cycling to the fullest"
        color="#F5755D"
        description={t("Om dat te kunnen")}
      />

      <FeatureSection
        title={t("De 6 Belangrijkste")}
        description={t("Download onze")}
        style={{ backgroundColor: '#F4DE58', color: 'black' }}
        bikeFittingButton={false}
      />


      <DataImageSection
        imagePath="img/newLandingPage/hero/4 bikefit.png"
        title={t("Doe jouw")}
        styledWord="online Online"
        color="#F5755D"
        style={{ textAlign: 'left', marginLeft: '0px' }}
        data={dataForDataImageSection2}
      />
      <Footer />
    </div>
  );
};

export default NewLandingPage;
